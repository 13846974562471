import { NgModule, ModuleWithProviders } from  '@angular/core';
import { CommonModule } from  '@angular/common';
import { AccessbilityService } from './accessibility.service';

/**
 * AccessbilityModule expÃµe o serviÃ§o AccessbilityService para o uso de outras aplicaÃ§Ãµes
 * @module
 */
@NgModule({
    imports: [CommonModule],
    providers: [AccessbilityService],
    exports: []
})
export class AccessbilityModule {
}
