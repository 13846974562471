
enum FormNotificationType { CLEAN, FIELD }

/**
 * Class modelo para validaÃ§Ã£o de formulÃ¡rios
 * @class
 */
class FormNotification {

    /**
     * Metodo construtor para validaÃ§Ã£o de formulÃ¡rios
     * @param {string} form
     * @param {FormNotificationType} type
     */
    constructor(
        public form: string, 
        public type: FormNotificationType) {
    }

}

export { FormNotificationType, FormNotification }
