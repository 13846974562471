
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';

import { DataProviderFactory } from './dataprovider-factory';
import { DatatableComponent } from './datatable.component';
import { DatatableButtonComponent } from './datatable-button.component';
import { DatatableService } from './datatable.service';
import { DatatableSplitbuttonModule } from '../extra-button/datatable-splitbutton.module';
import { FilterColumnModule } from './filter-column/filter-column.module';
import { ShowColumnModule } from './show-column/show-column.module';
import { Column } from './p-column.directive';
import { ActionButtonComponent } from './action-button/action-button.component';

/**
 * Class DatatableModule
 * @class
 */
@NgModule({
    imports: [
        CommonModule,
        ButtonModule,
        SplitButtonModule,
        TooltipModule,
        TableModule,
        BlockUIModule,
        ProgressSpinnerModule,
        DatatableSplitbuttonModule,
        HttpClientModule,
        PaginatorModule,
        FilterColumnModule,
        ShowColumnModule,
        InputTextModule
    ],
    declarations: [
        DatatableComponent,
        DatatableButtonComponent,
        Column,
        ActionButtonComponent
    ],
    providers: [
        { provide: DatatableService, useClass: DatatableService, deps: [HttpClient] },
        DataProviderFactory
    ],
    exports: [
        DatatableComponent,
        DatatableButtonComponent,
        FilterColumnModule,
        ShowColumnModule,
        Column
    ]
})
export class DatatableModule {
}
