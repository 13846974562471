import { FormNotification, FormNotificationType } from "./form-notification.type";

/**
 * Class modelo para validaÃ§Ã£o de campos de formulÃ¡rios
 * @class
 */
export class FieldFormNotification extends FormNotification {

    /**
     * MÃ©todo construtor para validaÃ§Ã£o de campos de formulÃ¡rios
     * @param {string} form
     * @param {string} field
     * @param {string} message
     */
    constructor(
        form: string,
        public field: string,
        public message: string) {
        super(form, FormNotificationType.FIELD);
    }

}
