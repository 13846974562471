import { Component, OnInit, OnDestroy, Input, TemplateRef } from '@angular/core';

import { ErrorStackService, NamedErrorType } from '@nuvem/angular-base';

import { Subscription } from 'rxjs';

/**
 * Class ErrorStackComponent
 * @class
 */
@Component({
    selector: 'basis-error-stack',
    templateUrl: './error-stack.component.html'
})
export class ErrorStackComponent implements OnInit, OnDestroy {

    /**
     * title property
     * @type {string}
     */
    @Input() title: string = 'Error Stack';

    /**
     * template property
     * @type {TemplateRef}
     */
    @Input() template: TemplateRef<any>;

    /**
     * errors property
     * @type {NamedErrorType}
     */
    errors: NamedErrorType[] = [];

    /**
     * errorSubscription property
     * @type {Subscription}
     */
    private errorSubscription: Subscription;

    /**
     * constructor method
     * @constructor
     * @param {ErrorStackService} errorStack
     */
    constructor(private errorStack: ErrorStackService) { }

    /**
     * ngOnInit method
     * @returns void
     */
    ngOnInit() {
        this.errorSubscription = this.errorStack.errors.subscribe((error: NamedErrorType) => {
            this.errors = [error, ...this.errors];
        });
    }

    /**
     * ngOnDestroy method
     * @returns void
     */
    ngOnDestroy() {
        this.errorSubscription.unsubscribe();
    }
}
