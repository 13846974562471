import { DataProviderType } from "./dataprovider-factory";
import { DatatablePaginationParameters } from "./datatable-pagination-parameters";


/**
 * Enum SelectModeType
 * @enum
 */
 export enum SelectModeType {
    Single = 'single',
    Multiple = 'multiple'
}

export interface DatatableParams {

    /** 
     * TÃ­tulo exibido no topo da tabela 
     */
    title?:  string;

    /**
     * Define se um split button deve ser exibido na tela
     */
    splitButton?:  any;

    /**
     * Valores que serÃ£o exibidos na tabela. Utilizado com o tipo 'memory'
     */
    value?: any[];

    /**
     * Define o tipo da tabela: memory | server
     */
    type?: DataProviderType;

    /**
     * NÃºmero de linhas apresentados por pÃ¡gina
     */
    rows?:  number;

    /**
     * Define as opÃ§Ãµes de nÃºmero de linhas por pÃ¡gina. Ex: [5, 10, 15]
     */
    rowsPerPageOptions?: number[];

    /**
     * Url que serÃ¡ utilizado para buscar os registros da tabela
     */
    url?: string;

    /**
     * ParÃ¢metros extras para a tabela. SerÃ£o setados apÃ³s a inicializaÃ§Ã£o.
     */
    extraParams?: any;

    /**
     * OpÃ§Ãµes para a paginaÃ§Ã£o
     */
    paginationParameters?: DatatablePaginationParameters;

    /**
     * Define se a paginaÃ§Ã£o deve ser exibida no tabela
     */
    showPaginationFooter?: boolean;

    /**
     * Determina se o botÃ£o de ediÃ§Ã£o de registros deve ser apresentado
     */
    disableEdit?: boolean;

    /**
     * Determina se o botÃ£o de visualizaÃ§Ã£o de registros deve ser apresentado
     */
    disableView?: boolean;

    /**
     * Determina se o botÃ£o de remoÃ§Ã£o de registros deve ser apresentado
     */
    disableDelete?: boolean;

    /**
     * Determina o tipo de seleÃ§Ã£o de registros. Ex: 'single'
     */
    selectionMode?: SelectModeType;

    /**
     * Determina se os botÃµes devem ser do tipo scroll
     */
    enableButtonsScroll?: boolean;

    /**
     * Mensagem exibida quando nÃ£o hÃ¡ registros na tabela
     */
    emptyMessage?: string;

    /**
     * Determina se os botÃµes verticais serÃ£o apresentados. Tem influÃªncia no espaÃ§o
     * ocupado pela tabela.
     */
    verticalButtons?: boolean;

    /**
     * Determina se os botÃµes padrÃµes serÃ£o apresentados. Tem influÃªncia no espaÃ§o
     * ocupado pela tabela.
     */
    defaultButtons ?: boolean;

    /**
     * Habilita o scroll da tabela
     */
    enableScroll ?: boolean;

    /**
     * Determina o tamanho para mÃ¡ximo para iniciar o scroll vertical.
     */
    scrollHeight?: string;

    /**
     * Perfis para ocultar os botÃµes
     */
    rulesToHide?: any;
    
    /**
     * Perfis para ocultar os botÃµes de visualizaÃ§Ã£o
     */
    rulesToHideView?: any;
    
    /**
     * Perfis para mostrar o botÃ£o de ediÃ§Ã£o
     */
    rulesToShowEdit?: any;

    /**
     * Perfis para ocultar os botÃ£o de ediÃ§Ã£o
     */
    rulesToHideEdit?: any;
    
    /**
     * Perfis para mostrar o botÃ£o delete
     */
    rulesToShowDelete?: any;

    /**
     * Perfis para ocultar os botÃ£o delete
     */
    rulesToHideDelete?: any;
    
    /**
     * Desabilita/Habilita o loading da tabela
     */
    disableLoadingBlockUI ?: boolean;
    
    /**
     * Classes css para as linhas da tabela 
     */
    rowStyleClass?: any;

    /**
     * Habilita/Desabilita os filtros por colunas
     */
    filterOnColumn ?: boolean;

    /**
     * Valores customizados para os options do select do filtro
     */
    customFilterOptions?: Object;

    /**
     * Habilita/Desabilita o controle de visibilidade das colunas
     */
    showVisibleColumnsControl?: boolean;

    /**
     * Habilidta/Desabilita o mode de ediÃ§Ã£o por linha
     */
    editMode?: string;

    /**
     * Identificar da tabela
     */
    dataKey?: string;
}