import { Injector, Input, OnInit, ViewChild, AfterViewInit, ElementRef, Output, EventEmitter, Directive } from "@angular/core";
import { WrappedFormControlSuperclass } from "@nuvem/angular-base";
import { ControlContainer } from "@angular/forms";

@Directive({
    selector: "[nField]"
})
export class FieldComponent<OuterType, InnerType = OuterType>
    extends WrappedFormControlSuperclass<OuterType, InnerType>
    implements OnInit, AfterViewInit {

    @Input() form: string;

    @Input() formControlName: string;

    @Input() extraParams: any;

    @Input() required: boolean;

    @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();

    @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

    @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("input", { static: false }) input: any;

    private _name: string;

    private _label: string;

    get name(): string {
        return this._name ? this._name : this.formControlName;
    }

    @Input()
    set name(value: string) {
        this._name = value;
    }

    get label(): string {
        return this.required ? `${this._label}*` : this._label;
    }

    @Input()
    set label(value: string) {
        this._label = value;
    }

    constructor(
        private controlContainer: ControlContainer,
        private injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        this.setValidators();
        this.setRequired();
    }

    setRequired() {
        if (this.required) {
            if (this.input instanceof ElementRef) {
                this.input.nativeElement.required = true;
            } else {
                this.input.required = true;
            }
        }
    }

    setElementRefAttr(attr: string, value: any) {
        if (value && this.input instanceof ElementRef) {
            this.input.nativeElement[attr] = value;
        }
    }

    setFormControlValidators() {
        if (this.controlContainer && this.formControlName) {
            let validator = this.controlContainer.control.get(this.formControlName).validator;
            if (validator) {
                this.formControl.setValidators(validator);
            }
        }
    }


    setValidators() {
        if (this.controlContainer && this.formControlName) {
            let validator = this.controlContainer.control.get(this.formControlName).validator;
            if (validator) {
                this.formControl.setValidators(validator);
            }
        }
    }

    ngAfterViewInit() {
        for (let i in this.extraParams) {
            this.input[i] = this.extraParams[i];
        }
    }

    onBlurEvent(event) {
        this.onBlur.emit(event);
    }

    onSelectEvent(event) {
        this.onSelect.emit(event);
    }

    onFocusEvent(event) {
        this.onFocus.emit(event);
    }
}
