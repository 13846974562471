import { Input, EventEmitter, QueryList, TemplateRef, AfterContentInit, Output, ContentChildren, ContentChild, Directive } from '@angular/core';
import { PrimeTemplate } from 'primeng/api';

@Directive({
    selector: 'p-column'
})
export class Column implements AfterContentInit {

    @Input() field: string;

    @Input() colId: string;

    @Input() sortField: string;

    @Input() filterField: string;

    @Input() header: string;

    @Input() footer: string;

    @Input() sortable: any;

    @Input() editable: boolean;

    @Input() filter: boolean;

    @Input() filterMatchMode: string;

    @Input() filterType: string = 'text';

    @Input() excludeGlobalFilter: boolean;

    @Input() rowspan: number;

    @Input() colspan: number;

    @Input() scope: string;

    @Input() style: any;

    @Input() styleClass: string;

    @Input() exportable: boolean = true;

    @Input() headerStyle: any;

    @Input() headerStyleClass: string;

    @Input() bodyStyle: any;

    @Input() bodyStyleClass: string;

    @Input() footerStyle: any;

    @Input() footerStyleClass: string;

    @Input() hidden: boolean;

    @Input() expander: boolean;

    @Input() selectionMode: string;

    @Input() filterPlaceholder: string;

    @Input() filterMaxlength: number;

    @Input() frozen: boolean;

    @Input() resizable: boolean = true;

    @Output() sortFunction: EventEmitter<any> = new EventEmitter();

    @ContentChildren(PrimeTemplate) templates: QueryList<any>;

    @ContentChild(TemplateRef) template: TemplateRef<any>;

    public headerTemplate: TemplateRef<any>;

    public bodyTemplate: TemplateRef<any>;

    public footerTemplate: TemplateRef<any>;

    public filterTemplate: TemplateRef<any>;

    public editorTemplate: TemplateRef<any>;

    public inputTemplate: TemplateRef<any>;

    public outputTemplate: TemplateRef<any>;

    ngAfterContentInit(): void {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'header':
                    this.headerTemplate = item.template;
                    break;

                case 'body':
                    this.bodyTemplate = item.template;
                    break;

                case 'footer':
                    this.footerTemplate = item.template;
                    break;

                case 'filter':
                    this.filterTemplate = item.template;
                    break;

                case 'editor':
                    this.editorTemplate = item.template;
                    break;

                case 'input':
                    this.inputTemplate = item.template;
                    break;

                case 'output':
                    this.outputTemplate = item.template;
                    break;

                default:
                    this.bodyTemplate = item.template;
                    break;
            }
        });
    }
}
