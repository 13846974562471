import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { ErrorProvider } from './providers/error.provider';

/**
 * Class ErrorService
 * @class
 */
@Injectable({
    providedIn: 'root',
})
export class ErrorService extends ErrorHandler {

    /**
     * Metodo construtor responÃ¡vel por injetar o serviÃ§o ErrorProvider
     * @param {ErrorProvider} providers
     */
    constructor(@Inject(ErrorProvider) private providers: ErrorProvider[]) {
        super();
    }

    /**
     * Metodo responsÃ¡vel por idetificar e obter os erros lanÃ§ados de requisiÃ§Ãµes http/https
     * @param {Error} error
     * @returns void
     */
    handleError(error: Error) {
        try {
            this.providers.forEach(p => {
                if (p.shouldHandle(error)) {
                    p.handle(error);
                }
            });
        } catch (e) {
        }
        super.handleError(error);
    }
}
