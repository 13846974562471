import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { NotificationProvider, ApplicationProblemType } from '@nuvem/angular-base';

/**
 * Class PageNotificationService
 * @class
 */
@Injectable({
    providedIn: 'root'
})
export class PageNotificationService extends NotificationProvider {

    /**
     * successSeverity property
     * @type {string}
     */
    private readonly successSeverity = 'success';

    /**
     * infoSeverity property
     * @type {string}
     */
    private readonly infoSeverity = 'info';

    /**
     * warnSeverity property
     * @type {string}
     */
    private readonly warnSeverity = 'warn';

    /**
     * errorSeverity property
     * @type {string}
     */
    private readonly errorSeverity = 'error';

    /**
     * createMsg property
     * @type {string}
     */
    private readonly createMsg = 'Registro incluÃ­do com sucesso!';

    /**
     * updateMsg property
     * @type {string}
     */
    private readonly updateMsg = 'Registro alterado com sucesso!';

    /**
     * deleteMsg property
     * @type {string}
     */
    private readonly deleteMsg = 'Registro excluÃ­do com sucesso!';

    /**
     * constructor method
     * @constructor
     * @param {MessageService} messageService
     */
    constructor(private messageService: MessageService) {
        super();
    }

    /**
     * addCreateMsg method
     * @param {string} title
     */
    addCreateMsg(title?: string) {
        this.addSuccessMessage(this.createMsg, title);
    }

    /**
     * addSuccessMessage method
     * @param {string} message
     * @param {string} title
     */
    addSuccessMessage(message: string, title?: string) {
        this.addMsg(this.successSeverity, message, title);
    }

    /**
     * addMsg method
     * @param {string} severity
     * @param {string} msg
     * @param {string} title
     */
    private addMsg(severity: string, msg: string, title?: string) {
        this.messageService.add({
          severity: severity,
          summary: title,
          detail: msg
        });
    }

    /**
     * addUpdateMsg method
     * @param {string} title
     */
    addUpdateMsg(title?: string) {
        this.addSuccessMessage(this.updateMsg, title);
    }

    /**
     * addDeleteMsg method
     * @param {string} title
     */
    addDeleteMsg(title?: string) {
        this.addSuccessMessage(this.deleteMsg, title);
    }

    /**
     * addInfoMessage method
     * @param {string} message
     * @param {string} title
     */
    addInfoMessage(message: string, title?: string) {
        this.addMsg(this.infoSeverity, message, title);
    }

    /**
     * addWarnMessage method
     * @param {string} message
     * @param {string} title
     */
    addWarnMessage(message: string, title?: string) {
        this.addMsg(this.warnSeverity, message, title);
    }

    /**
     * addErrorMessage method
     * @param {string} message
     * @param {string} title
     */
    addErrorMessage(message: string, title?: string) {
        this.addMsg(this.errorSeverity, message, title);
    }

    /**
     * addErrorProblem method
     * @param {ApplicationProblemType} problem
     */
    addErrorProblem(problem: ApplicationProblemType): void {
        this.addMsg(this.errorSeverity, problem.detail, problem.title);
    }

}
