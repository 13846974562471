import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonMaskService } from './common-mask.service';
/**
 * CommonMaskModule expÃµe o servico CommonMaskService para o uso de outras aplicaÃ§Ãµes
 * @class
 */
@NgModule({
    imports: [],
    declarations: [],
    providers: [
        CommonMaskService
    ],
    exports: []
})
export class CommonMaskModule {
}
