import { Component, Input, OnInit, QueryList, Output, EventEmitter } from '@angular/core';
import { PageNotificationService } from './../../page-notification/page-notification.service';

@Component({
    selector: 'basis-show-column',
    templateUrl: './show-column.component.html'
})
export class ShowColumn implements OnInit {

    /**
     * atributo que representa as colunas da tabela
     * @type {QueryList}
     */
    @Input() columns: QueryList<any>;

    /**
     * lista de colunas disponÃ­vels na tabela
     * @type {Array}
     */
    options: any[] = [];

    /**
     * Valores do select de colunas
     * @type {Array}
     */
    values: any[] = [];

    /**
     * Ãltimo valor vÃ¡lido para o select.
     * @type {Array}
     */
    private lastValue: any[] = [];

    /**
     * Evento para atualizar a visibilidade das colunas da tabela
     * @type {EventEmitter}
     */
    @Output() updateVisibleColumns: EventEmitter<any> = new EventEmitter();

    constructor(private pageNotificationService: PageNotificationService) {}

    ngOnInit () {
        this.columns.forEach((column) => {
            const col = {field: column.field, filterType: column.filterType, header: column.header};
            this.options.push(col);
            this.values.push(col)
            this.lastValue.push(col);
        });
    }

    mostrarColunas(event) {
        if (this.values.length) {
            this.lastValue = event.value;
            this.updateVisibleColumns.emit(this.values);
		} else {
            this.lastValue.map((item) => this.values.push(item));
			this.pageNotificationService.addErrorMessage('NÃ£o Ã© possÃ­vel exibir menos de uma coluna');
        }
    }
}
