import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHeaders } from "@angular/common/http";
import { Token } from './token';
import { AuthConfig, AUTH_CONFIG } from '../config/auth-config';

/**
 * InplementaÃ§Ã£o de serviÃ§o JWTTokenService
 * @class
 */
@Injectable({
    providedIn: 'root',
})
export class JWTTokenService extends Token {

    /**
     * TOKEN_PREFIX  property
     * @type {string}
     */
    public static readonly TOKEN_PREFIX = "Bearer";

    /**
     * HEADER_STRING property
     * @type {string}
     */
    public static readonly HEADER_STRING = "Authorization";

    /**
     * Metodo construtor responsÃ¡vel por injetar as configuraÃ§Ãµes de autenticaÃ§Ã£o
     * @constructor
     * @param {AuthConfig} config
     */
    constructor(@Inject(AUTH_CONFIG) private config: AuthConfig) {
        super();
    }
    /**
     * Metodo responsÃ¡vel pela extraÃ§Ã£o de tokes do heaedrs das requisiÃ§Ãµes
     * @public
     * @param {HttpHeaders} headers
     * @returns string
     */
    extractTokenFromHeaders(headers: HttpHeaders): String {
        return headers.get(JWTTokenService.HEADER_STRING);
    }

    /**
     * Metodo responsÃ¡vel pela adiÃ§Ã£o dos tokens no storage
     * @public
     * @param {String} token
     * @returns void
     */
    storeToken(token: String) {
        this.config.storage.setItem(
            this.config.tokenStorageIndex,
            token.toString()
        );
    }

    /**
     * Metodo responsÃ¡vel por injetar token no header das requisiÃ§Ãµes
     * @public
     * @param {HttpRequest<any>} request
     * @returns HttpRequest<any>
     */
    setTokenInHeader(request: HttpRequest<any>): HttpRequest<any> {
        const req: HttpRequest<any> = request.clone({
            headers: new HttpHeaders({
                [JWTTokenService.HEADER_STRING]: this.config.storage.getItem(this.config.tokenStorageIndex)
            })
        });
        return req;
    }

    /**
     * Metodo responsÃ¡vel por verificar a existencia de um token de autenticaÃ§Ã£o
     * @returns boolean
     * @public
     */
    hasToken(): boolean {
        return null !== this.config.storage.getItem(this.config.tokenStorageIndex);
    }

}
