import { ApplicationProblemType } from "../../error/types/application-problem.type";
import { FormNotification } from "../types/form-notification.type";

/**
 * Class provider para definiÃ§Ã£o bÃ¡sica de tratamento de erros de formulÃ¡rios
 * @class
 */
export class FormNotificationProvider extends ApplicationProblemType {

    /**
     * Metodo construtor para validaÃ§Ã£o de formulÃ¡rios
     * @param {string} type
     * @param {string} title
     * @param {string} detail
     * @param {Number} status
     * @param {string} instance
     * @param {Array<string>} stacktrace
     * @param {ApplicationProblemType} cause
     * @param {FormViolation} violations
     */
    constructor(
        type: string | 'about:blank',
        title: string,
        detail?: string,
        status?: Number,
        instance?: string,
        stacktrace?: Array<string>,
        cause?: ApplicationProblemType,
        public violations?: FormNotification[]) {
        super(type, title, detail, status, instance, stacktrace, cause);
    }

}
