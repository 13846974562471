import { Component, Injector } from "@angular/core";

import { Subscription } from "rxjs";

import { provideValueAccessor, FormNotificationService, FormNotification, FieldFormNotification } from "@nuvem/angular-base";
import { FieldComponent } from "../field.component";
import { ControlContainer } from '@angular/forms';

@Component({
    selector: "[nCheck]",
    templateUrl: "./check.component.html",
    providers: [ provideValueAccessor(CheckComponent) ],
})
export class CheckComponent extends FieldComponent<string> {

    private subscription: Subscription;

    message: String;

    /**
     * MÃ©todo construtor responsÃ¡vel por carregar o serviÃ§o de validaÃ§Ã£o
     * @param formNotificationService
     */
    constructor(
        controlContainer: ControlContainer,
        injector: Injector,
        protected formNotificationService: FormNotificationService) {
        super(controlContainer, injector);
    }

    /**
     * MÃ©todo Inicial para subscriÃ§Ã£o no endpoint responsÃ¡vel por validar o formulÃ¡rio
     */
    ngOnInit(): void {
        super.ngOnInit()
        this.subscription = this.formNotificationService
            .notifications(this.form, this.name)
            .subscribe((notification: FormNotification) => {
                if (notification instanceof FieldFormNotification) {
                    this.message = notification.message;
                } else {
                    this.message = null;
                }
            });
    }

    /**
     * MÃ©todo responsÃ¡vel por realizar a saida das subscriÃ§Ãµes do endpont de validaÃ§Ã£o
     */
    ngOnDestroy(): void {
        super.ngOnDestroy()
        this.subscription.unsubscribe();
    }


}
