
import { Injectable } from '@angular/core';
import { Authorization } from './authorization';
import { Authentication } from '../authentication';
import { User } from '../user';

/**
 * ServiÃ§o de autorizaÃ§Ã£o 
 * @class
 */
@Injectable({
    providedIn: 'root',
})
export class AuthorizationService extends Authorization {

    /**
     * MÃ©todo construtor para a injeÃ§Ã£o do serviÃ§o de configuraÃ§Ã£o AuthConfig
     * @constructor
     * @param {AuthConfig} config
     */
    constructor(private authenticationService: Authentication<User>) {
        super();
    }

    /**
     * MÃ©todo responsÃ¡vel por identificar as regras de acesso
     * @public
     * @param {any} role
     * @returns boolean
     */
    hasRole(role: any): boolean {
        if (role) {
            const user = this.authenticationService.getUser();
            if (role instanceof Array) {
                return user.roles.some((userRole: string) => role.some((checkRole: string) => userRole === checkRole));
            }
            return user.roles.some((userRole: string) => userRole === role);
        }
        return true;
    }

}
