import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { CrudService, CRUD_SERVICE } from "./crud-service.service";

@Injectable()
export class CrudResolveGuard implements Resolve<any> {

  constructor(@Inject(CRUD_SERVICE) private crudService: CrudService<any, any>) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.crudService.find(route.paramMap.get('id'));
  }

}
