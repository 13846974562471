import { Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationProvider } from '../providers/notification.provider';
import { HttpGenericErrorType } from '../types/http-generic-error.type';
import { ErrorProvider } from '../providers/error.provider';

/**
 * ServiÃ§o HttpGenericErrorService
 * @class
 */
@Injectable({
    providedIn: 'root',
})
export class HttpGenericErrorService implements ErrorProvider {

    /**
     * Metodo construtor responsÃ¡velpor injetar o serviÃ§o NotificationProvider
     * @param {NotificationProvider} notification
     * @constructor
     */
    constructor(private notification: NotificationProvider, private ngZone: NgZone) { }

    /**
     * Metodo responsÃ¡vel pela identificaÃ§Ã£o de erros
     * @public
     * @param {Error | HttpErrorResponse} error
     * @returns Boolean
     */
    shouldHandle(error: Error | HttpErrorResponse): Boolean {
        return error instanceof HttpErrorResponse && 
            !(error.headers.get('Content-Type') === 'application/problem+json' && 
            error.error);
    }

    /**
     * Metodo pela adiÃ§Ã£o de erros
     * @public
     * @param {HttpErrorResponse} error
     * @returns void
     */
    handle(error: HttpErrorResponse): void {
        const generic = this.getErrorByCode(error);
        this.ngZone.run(() => { 
            this.notification.addErrorMessage(generic.detail, generic.title);
        });
    }

    /**
     * Metodo para obter erros a partir de um cÃ³digo
     * @public
     * @param {HttpErrorResponse} error
     * @returns HttpGenericErrorType
     */
    public getErrorByCode(error: HttpErrorResponse): HttpGenericErrorType {
        const generic = GENERIC_ERRORS[error.status];

        if (generic === undefined) {
            return new HttpGenericErrorType(
                error.status,
                error.statusText,
                error.message
            );
        }

        return generic;
    }

}
/**
 * Constante que define tipos de erros bÃ¡sicos
 * @const GENERIC_ERRORS
 */
export const GENERIC_ERRORS = {
    401: new HttpGenericErrorType(401,
        'NÃ£o Autenticado',
        'Essa requisiÃ§Ã£o requer um usuÃ¡rio autenticado.'),

    403: new HttpGenericErrorType(403,
        'Proibido',
        'Essa requisiÃ§Ã£o requer uma permissÃ£o no servidor da aplicaÃ§Ã£o que o usuÃ¡rio nÃ£o possui.'),

    404: new HttpGenericErrorType(404,
        'NÃ£o encontrado',
        'Essa requisiÃ§Ã£o nÃ£o pode ser encontrada.'),

    500: new HttpGenericErrorType(500,
        'Erro desconhecido',
        'O servidor encontrou uma condiÃ§Ã£o inesperada.'),

    502: new HttpGenericErrorType(502,
        'Bad Gateway',
        'O servidor recebeu uma resposta invÃ¡lida de um serviÃ§o que utilizou para cumprir a requisiÃ§Ã£o'),

    503: new HttpGenericErrorType(503,
        'ServiÃ§o IndisponÃ­vel',
        'O servidor nÃ£o pode atender a requisiÃ§Ã£o nesse momento. Esta Ã© uma condiÃ§Ã£o temporÃ¡ria, tente novamente mais tarde'),

    504: new HttpGenericErrorType(504,
        'Gateway Timeout',
        'O servidor nÃ£o conseguiu receber uma resposta a tempo esperado por um serviÃ§o que utilizou para cumprir a requisiÃ§Ã£o')
};
