/**
 * Class DatatableClickEvent
 * @class
 */
export class DatatableClickEvent {
    /**
     * constructor method
     * @constructor
     * @param {string} button
     * @param {any} selection
     */
    constructor(public button: string, public selection: any) {}
}
