import {
    Component,
    ViewChild,
    Input,
    Output,
    ContentChildren,
    QueryList,
    ChangeDetectorRef,
    AfterViewInit,
    AfterContentInit,
    OnInit,
    EventEmitter,
    ViewEncapsulation
} from '@angular/core';
import { Table as DataTable } from 'primeng/table';
import { DataProvider } from './dataprovider';
import { DataProviderFactory, DataProviderType } from './dataprovider-factory';
import { DatatableButtonComponent } from './datatable-button.component';
import { DatatableClickEvent } from './datatable-click-event';
import { DatatablePaginationParameters } from './datatable-pagination-parameters';
import { HttpErrorResponse } from '@angular/common/http';
import { Authorization } from '@nuvem/angular-base';
import { Column } from './p-column.directive';
import { DatatableParams, SelectModeType } from './datatable-params';

/**
 * Class DatatableComponent
 * @class
 */
@Component({
    selector: 'basis-datatable',
    templateUrl: './datatable.component.html',
    styleUrls: ['./datatable.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DatatableComponent
    implements DatatableParams, AfterViewInit, AfterContentInit, OnInit {
    isShowGlobalFilter = false;
    isShowFilterRow = false;

    get showGlobalFilter(): boolean {
        return this.isShowGlobalFilter;
    }

    set showGlobalFilter(showGlobalFilter: boolean) {
        this.isShowGlobalFilter = showGlobalFilter;
    }

    get showFilterRow(): boolean {
        return this.isShowFilterRow;
    }

    set showFilterRow(ShowFilterRow: boolean) {
        this.isShowFilterRow = ShowFilterRow;
    }

    /**
     * selectedRow property
     * @type {any}
     */
    selectedRow: any;

    /**
     * dataProvider property
     * @type {DataProvider}
     */
    dataProvider: DataProvider;

    /**
     * filterParams property
     * @type {any}
     */
    filterParams: any = {};

    /**
     * footerMsg property
     * @type {string}
     */
    footerMsg: string = '';

    /**
     * orderInSort property
     * @type {boolean}
     */
    orderInSort: boolean = false;

    /**
     * title property
     * @type {string}
     */
    @Input() title: string;

    /**
     * splitButton property
     * @type {any}
     */
    @Input() splitButton: any;

    /**
     * value property
     * @type {any}
     */
    @Input() value: any[] = [];

    /**
     * type property
     * @type DataProviderType
     */
    @Input() type: DataProviderType = DataProviderType.Server;

    /**
     * rows property
     * @type {number}
     */
    @Input() rows: number = 5;

    /**
     * rowsPerPageOptions property
     * @type {number[]}
     */
    @Input() rowsPerPageOptions: number[];

    /**
     * url property
     * @type {string}
     */
    @Input() url: string;

    /**
     * extraParams property
     * @type {any}
     */
    @Input() extraParams: any;

    /**
     * parametros recebidos do componente pai
     */
    @Input() params: DatatableParams;

    /**
     * paginationParameters property
     * @type {DatatablePaginationParameters}
     */
    @Input() paginationParameters: DatatablePaginationParameters;

    /**
     * showPaginationFooter property
     * @type {boolean}
     */
    @Input() showPaginationFooter = false;

    /**
     * disableEdit property
     * @type {boolean}
     */
    @Input() disableEdit = false;

    /**
     * disableView property
     * @type {boolean}
     */
    @Input() disableView = false;

    /**
     * disableDelete property
     * @type {boolean}
     */
    @Input() disableDelete = false;

    /**
     * selectionMode property
     * @type {string}
     */
    @Input() selectionMode: SelectModeType = SelectModeType.Single;

    /**
     * enableButtonsScroll property
     * @type {boolean}
     */
    @Input() enableButtonsScroll = false;

    /**
     * emptyMessage property
     * @type {string}
     */
    @Input() emptyMessage = 'Nenhum registro encontrado.';

    /**
     * verticalButtons property
     * @type {boolean}
     */
    @Input() verticalButtons = true;

    /**
     * defaultButtons property
     * @type {boolean}
     */
    @Input() defaultButtons = true;

    /**
     * enableScroll property
     * @type {boolean}
     */
    @Input() enableScroll = false;

    /**
     * scrollHeight property
     * @type {string}
     */
    @Input() scrollHeight: string;

    /**
     * scrollWidth property
     * @type {string}
     */
    @Input() scrollWidth: string;

    /**
     * rulesToHide property
     * @type {any}
     */
    @Input() rulesToHide: any;

    /**
     * rulesToShowEdit property
     * @type {any}
     */
    @Input() rulesToShowEdit: any;

    /**
     * rulesToShowDelete property
     * @type {any}
     */
    @Input() rulesToShowDelete: any;

    /**
     * rulesToHideView property
     * @type {any}
     */
    @Input() rulesToHideView: any;

    /**
     * rulesToHideEdit property
     * @type {any}
     */
    @Input() rulesToHideEdit: any;

    /**
     * rulesToHideDelete property
     * @type {any}
     */
    @Input() rulesToHideDelete: any;

    /**
     * horizontalButtonsHeader property
     * @type {string}
     */
    horizontalButtonsHeader: string;

    /**
     * disableLoadingBlockUI property
     * @type {boolean}
     */
    @Input() disableLoadingBlockUI = false;

    /**
     * rowStyleClass property
     * @type {any}
     */
    @Input() rowStyleClass: any;

    /**
     * filterOnColumn property
     */
    @Input() filterOnColumn = false;

    /**
     * propriedade interna para controlar a apresentaÃ§Ã£o dos filtros
     */
    showFilterOnColumn = false;

    /**
     * filterSelectOptions property
     */
    filterSelectOptions: any[] = [];

    /**
     * updateFiltroHistorico property
     */
    updateFilterColumn: boolean = false;

    /**
     * customFilterOptions property
     */
    @Input() customFilterOptions: Object = {};

    /**
     * buttonClick property
     * @type {EventEmitter<any>}
     */
    @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();

    /**
     * pDatatableComponent property
     * @type {Table}
     */
    @ViewChild(DataTable, { static: true }) pDatatableComponent: DataTable;

    /**
     * extraButtons property
     * @type {QueryList<DatatableButtonComponent>}
     */
    @ContentChildren(DatatableButtonComponent) extraButtons: QueryList<DatatableButtonComponent>;

    /**
     * columns property
     * @type {any[]}
     */
    @ContentChildren(Column) columns: Column[];

    /**
     * startedLoading property
     * @type {EventEmitter<void>}
     */
    @Output() startedLoading: EventEmitter<void> = new EventEmitter<void>();

    /**
     * finishedLoading property
     * @type {EventEmitter<HttpErrorResponse>}
     */
    @Output() finishedLoading: EventEmitter<HttpErrorResponse> = new EventEmitter<HttpErrorResponse>();

    /**
     * onRowSelected property
     * @type {EventEmitter<any>}
     */
    @Output() onRowSelected: EventEmitter<any> = new EventEmitter<any>();

    /**
     * showHorizontalButtonsHeader property
     * @type {boolean}
     */
    showHorizontalButtonsHeader = false;

    /**
     * blockContent
     * @type {boolean}
     */
    blockContent = false;

    /**
     * visibleColumns property
     * @type {Object}
     */
    visibleColumns: Object = {};

    /**
     * showVisibleColumnsControl property
     * @type {boolean}
     */
    @Input() showVisibleColumnsControl: boolean = false;

    /**
     * Controle interno de visibilidade das colunas de forma dinÃ¢mica
     */
    showVisibleColumns: boolean;

    /**
     * editMode property
     * @type {string}
     */
    @Input() editMode: string;

    /**
     * dataKey property
     * Identificador Ãºnico para cada registro da tabela
     * @type {string}
     */
    @Input() dataKey: string = 'id';

    /**
     * onRowEditEvent property
     * Evento disparado quando o usuÃ¡rio realiza aÃ§Ãµes de ediÃ§Ã£o em uma linha da tabela
     * @type {EventEmitter<void>}
     */
    @Output() onRowEditEvent: EventEmitter<any> = new EventEmitter<any>();

    clonedRowData: { [s: string]: any } = {};

    /**
     * errorMessage property
     * @type {string}
     */
    private errorMessage = 'Ocorreu um erro ao carregar os dados da tabela. Tente novamente.';

    /**
     * originalEmptyMessage property
     * @type {string}
     */
    private originalEmptyMessage: string;

    editingRow = false;

    /**
     * constructor method
     * @constructor
     * @param {ChangeDetectorRef} changeDetectorRef
     * @param {DataProviderFactory} dataProviderFactory
     * @param {Authorization} authorizationService
     */
    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private dataProviderFactory: DataProviderFactory,
        private authorizationService: Authorization
    ) {}

    /**
     * ngOnInit method
     * @returns void
     */
    ngOnInit() {
        this.dataProvider = this.dataProviderFactory.create(this.type, this);
        this.pDatatableComponent.rows = this.rows;

        this.showFilterOnColumn = this.filterOnColumn;
        this.showVisibleColumns = this.showVisibleColumnsControl;

        this.subscribeToLoadingEvents();
        this.initRowsPerPageOptionsIfPresent();
        this.defineIfShouldShowHorizontalButtonsHeader();
        this.updateOnValueChange();

        this.finishedLoading.subscribe(() => {
            this.updateOnValueChange();
        });

        if (this.params) {
            Object.keys(this.params).forEach(input => {
                this[input] = this.params[input];
            });
        }
    }

    /**
     * subscribeFromValueChange method
     * @returns void
     */
    private updateOnValueChange() {
        this.filterSelectOptions = [];
        if (this.value) {
            this.value.map((item: any) => {
                this.filterSelectOptions.push(item);
            });
        }
    }

    /**
     * subscribeToLoadingEvents method
     * @returns void
     */
    private subscribeToLoadingEvents() {
        if (!this.disableLoadingBlockUI) {
            this.originalEmptyMessage = this.emptyMessage;
            this.startedLoading.subscribe(() => this.onStartedLoading());
            this.finishedLoading.subscribe(error =>
                this.onFinishedLoading(error)
            );
        }
    }

    /**
     * onStartedLoading method
     * @returns void
     */
    private onStartedLoading() {
        this.blockContent = true;
        this.emptyMessage = this.originalEmptyMessage;
    }
    /**
     * onFinishedLoading method
     * @param {HttpErrorResponse} error
     * @returns void
     */
    private onFinishedLoading(error: HttpErrorResponse) {
        this.blockContent = false;
        if (error) {
            this.emptyMessage = this.errorMessage;
        }
    }

    /**
     * initRowsPerPageOptionsIfPresent method
     * @returns void
     */
    private initRowsPerPageOptionsIfPresent() {
        if (this.rowsPerPageOptions) {
            this.pDatatableComponent.rowsPerPageOptions = this.rowsPerPageOptions;
        }
    }

    /**
     * defineIfShouldShowHorizontalButtonsHeader method
     * @returns void
     */
    private defineIfShouldShowHorizontalButtonsHeader() {
        if (this.horizontalButtonsHeader) {
            this.showHorizontalButtonsHeader = true;
        }
    }

    /**
     * ngAfterViewInit method
     * @returns void
     */
    ngAfterViewInit() {
        this.setExtraParams();
        this.pDatatableComponent.columns = this.columns;
        this.pDatatableComponent.ngAfterContentInit();
        this.setAllColumnsVisible();
        this.changeDetectorRef.detectChanges();
    }

    /**
     * setExtraParams method
     * @returns void
     */
    private setExtraParams() {
        for (let i in this.extraParams) {
            this.pDatatableComponent[i] = this.extraParams[i];
        }
    }
    /**
     * ngAfterContentInit method
     * @returns void
     */
    ngAfterContentInit() {
        this.extraButtons.forEach(button => {
            button.click.subscribe(event => {
                this.onClick(button.name, event);
            });
        });
    }

    /**
     * onClick method
     * @param {string} button
     * @param {any} event
     * @return void
     */
    onClick(button: string, event: any) {
        this.buttonClick.emit(
            new DatatableClickEvent(button, this.selectedRow)
        );
        event.stopPropagation();
        this.resetSelectedRow(button);
    }

    /**
     * onSelectChange method
     * @param {any} event
     * @returns void
     */
    onSelectChange(event: any) {
        this.onRowSelected.emit(this.selectedRow);
        if (Array.isArray(this.selectedRow) && this.selectedRow.length > 1) {
            this.updateMultipleButtonsStatus(true);
        } else {
            this.updateMultipleButtonsStatus(false);
        }
    }

    /**
     * updateMultipleButtonsStatus method
     * @param {boolean} hasMultipleSelection
     * @return void
     */
    private updateMultipleButtonsStatus(hasMultipleSelection: boolean) {
        this.extraButtons.forEach(button => {
            if (hasMultipleSelection && !button.allowMultipleSelection) {
                button.disabled = true;
            } else {
                button.disabled = false;
            }
        });
    }

    /**
     * refresh method
     * @param query
     * @returns void
     */
    refresh(query?: any) {
        this.dataProvider.load(query);
    }

    /**
     * resetSelectedRow method
     * @param button
     * @returns void
     */
    resetSelectedRow(button: string) {
        if (button === 'delete') {
            this.selectedRow = null;
        }
    }

    /**
     * reset method
     * @returns void
     */
    reset() {
        this.pDatatableComponent.first = 0;
        this.dataProvider.reset();
        this.refresh();
    }

    /**
     * paginationFooterText method
     * @param {string} msg
     * @returns string
     */
    paginationFooterText(msg: string): string {
        const registros = this.totalRecords() === 1 ? 'registro' : 'registros';
        if (msg === '') {
            return `Exibindo: ${this.currentFirstIndex()} a ${this.currentLastIndex()} de ${this.totalRecords()} ${registros}`;
        }
        msg = msg.replace('{totalRecords}', this.totalRecords().toString());
        msg = msg.replace(
            '{currentFirstIndex}',
            this.currentFirstIndex().toString()
        );
        msg = msg.replace(
            '{currentLastIndex}',
            this.currentLastIndex().toString()
        );
        return msg;
    }

    /**
     * currentFirstIndex method
     * @returns number
     */
    currentFirstIndex(): number {
        return this.pDatatableComponent.first + 1;
    }

    /**
     * currentLastIndex method
     * @returns number
     */
    currentLastIndex(): number {
        const currentLastIndex: number =
            this.pDatatableComponent.first + this.pDatatableComponent.rows;
        if (currentLastIndex > this.totalRecords()) {
            return this.totalRecords();
        }
        return currentLastIndex;
    }

    /**
     * totalRecords method
     * @returns number
     */
    totalRecords(): number {
        return this.pDatatableComponent.totalRecords;
    }

    /**
     * checkRolesToButton method
     * @param {string} button
     * @return boolean
     */
    checkRolesToButton(button: string): boolean {
        if (this[button]) {
            if (
                button === 'rulesToHide' ||
                button === 'rulesToShowEdit' ||
                button === 'rulesToShowDelete'
            ) {
                this.checkUseButtons(button);
                return this.authorizationService.hasRole(this[button]);
            }
            return !this.authorizationService.hasRole(this[button]);
        }
        return true;
    }

    /**
     * checkUseButtons method
     * @param {string} button
     * @returns void
     */
    checkUseButtons(button: string): void {
        if (this[button] && this[button.replace('Show', 'Hide')]) {
            throw new Error(
                'NÃ£o Ã© possÃ­vel definir dois valores de roles para os botÃµes padrÃµes.'
            );
        }
    }

    /**
     * filter method
     * @returns void
     */
    filter() {
        for (let prop in this.filterParams) {
            if (
                this.filterParams[prop] === '' ||
                this.filterParams[prop].length === 0
            ) {
                delete this.filterParams[prop];
            }
        }
        if (Object.getOwnPropertyNames(this.filterParams).length === 0) {
            this.reset();
        } else {
            this.refresh(this.filterParams);
        }
    }

    /**
     * MÃ©todo para filtrar por colunas
     * @param column chave da coluna na tabela
     * @param filters valor do filtro
     */
    filterColumn(column: string, filters: any) {
        const values = [];
        if (filters instanceof Array) {
            values[column] = [];
            for (let item in filters) {
                let val =
                    filters[item] instanceof Object
                        ? filters[item].value
                        : filters[item];
                values[column].push(val);
            }
        } else {
            values[column] = filters;
        }
        this.filterParams = { ...this.filterParams, ...values };

        return this.filter();
    }

    /**
     * Inicia todas as colunas da tabela como visÃ­veis
     */
    setAllColumnsVisible() {
        this.columns.forEach(col => {
            this.visibleColumns[col.field] = 'table-cell';
        });
    }

    /**
     * Verifica se uma coluna deve estar visÃ­vel
     * @param column string
     * @param visibleColumns Array
     */
    visibleColumnCheck(column: string, visibleColumns: []) {
        return visibleColumns.some((item: any) => {
            return item.field ? item.field == column : true;
        });
    }

    /**
     * Atualzia os valores da variÃ¡vel 'visibleColuns' para mostrar ou nÃ£o as colunas
     * @param columns array
     */
    updateVisibleColumns(columns: []) {
        for (let col in this.visibleColumns) {
            if (this.visibleColumnCheck(col, columns)) {
                this.visibleColumns[col] = 'table-cell';
            } else {
                this.visibleColumns[col] = 'none';
            }
        }
    }

    onRowEditInitEvent(event, rowData: any) {
        // const tr = event.srcElement.closest('tr');

        // setTimeout(() => {
        //     this.editingRow = true;
        //     const top = tr.offsetTop;
        //     const height = tr.offsetHeight;
        //     const editButtons = tr.querySelector('.editar-campos');

        //     editButtons.style.top = (top + height) + 'px';
        //     editButtons.style.height ='75px';
        //     editButtons.style.backgroundColor = '#d0d0d0';
        //     editButtons.style.opacity = 1;

        //     this.clonedRowData[rowData.id] = {...rowData};
        //     this.onRowEditEvent.emit({event: "init", data: rowData});
        // }, null , tr);

    }

    onRowEditSaveEvent(event, rowData: any) {
        this.onRowEditEvent.emit({event: "save", data: rowData});
        this.resetEditingMode(event);
    }

    onRowEditCancelEvent(event, rowData: any, index: number) {
        this.value[index] = this.clonedRowData[rowData.id];

        delete this.clonedRowData[rowData[this.dataKey]];
        this.onRowEditEvent.emit({event: "cancel", data: rowData});

        this.resetEditingMode(event);
    }

    resetEditingMode(event) {
        const editButtons = event.srcElement.closest('tr').querySelector('.editar-campos');

        editButtons.style.opacity = 0;
        editButtons.style.backgroundColor = '#0000006b';
        editButtons.style.display = 'none';

        this.editingRow = false;
    }

    editarLinhaOver(event) {
        // if (!this.editingRow && this.editMode == 'row') {
        //     const top = event.srcElement.closest('tr').offsetTop;
        //     const height = event.srcElement.closest('tr').offsetHeight;
        //     const editButtons = event.srcElement.closest('tr').querySelector('.editar-campos');

        //     editButtons.style.display = 'flex';
        //     editButtons.style.top = top + 'px';
        //     editButtons.style.height = height + 'px';
        //     editButtons.style.backgroundColor = '#0000006b';
        //     editButtons.style.opacity = 1;
        // }
    }

    editarLinhaOut(event) {
        // if (!this.editingRow) {
        //     const editButtons = event.srcElement.closest('tr').querySelector('.editar-campos');
        //     editButtons.style.display = 'none';
        //     editButtons.style.opacity = 0;
        // }
    }

}
