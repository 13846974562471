import { HttpRequest, HttpHeaders } from "@angular/common/http";

/**
 * Classe abstrata para gerencia de tokens
 * @class
 */
export abstract class Token {

    /**
     * Metodo responsÃ¡vel pela extraÃ§Ã£o de tokes do heaedrs das requisiÃ§Ãµes
     * @public
     * @param {HttpHeaders} headers
     * @returns strng
     */
    abstract extractTokenFromHeaders(headers: HttpHeaders): String;

    /**
     * Metodo responsÃ¡vel pela adiÃ§Ã£o dos tokens no storage
     * @public
     * @param {String} token
     * @returns void
     */
    abstract storeToken(token: String);

    /**
     * Metodo responsÃ¡vel por injetar token no header das requisiÃ§Ãµes
     * @public
     * @param {HttpRequest<any>} request
     * @returns HttpRequest<any>
     */
    abstract setTokenInHeader(request: HttpRequest<any>): HttpRequest<any>;

    /**
     * Metodo responsÃ¡vel por verificar a existencia de um token de autenticaÃ§Ã£o
     * @returns boolean
     * @public
     */
    abstract hasToken(): boolean;

}
