
/**
 * Classe abstrata para serviÃ§o de autorizaÃ§Ã£o
 * @class
 */
export abstract class Authorization {

    /**
     * MÃ©todos responsÃ¡vel por identificar as regras de acesso
     * @public
     * @abstract
     * @param {any} role
     * @returns boolean
     */
    abstract hasRole(role: any): boolean;

}
