import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';

export enum MenuOrientation {
    STATIC,
    OVERLAY,
    SLIM,
    HORIZONTAL
}

/**
 * Class MenuService
 * @class
 */
@Injectable({
    providedIn: 'root'
})
export class MenusService {

    private menuSource = new Subject<string>();

    private resetSource = new Subject();

    menuSource$ = this.menuSource.asObservable();
    
    resetSource$ = this.resetSource.asObservable();

    layoutMode: MenuOrientation = MenuOrientation.STATIC;

    menuHoverActive: boolean;

    itens: MenuItem[] = [];

    resetMenu: boolean;

    isMobile: boolean;

    isTablet: boolean;

    isDesktop: boolean;

    overlayMenuActive: boolean;

    staticMenuMobileActive: boolean;

    staticMenuDesktopInactive: boolean;

    isOverlay() {
        return this.layoutMode === MenuOrientation.OVERLAY;
    }

    isHorizontal() {
        return this.layoutMode === MenuOrientation.HORIZONTAL;
    }

    isSlim() {
        return this.layoutMode === MenuOrientation.SLIM;
    }

    isStatic() {
        return this.layoutMode === MenuOrientation.STATIC;
    }

    changeToStaticMenu() {
        this.layoutMode = MenuOrientation.STATIC;
    }

    changeToOverlayMenu() {
        this.layoutMode = MenuOrientation.OVERLAY;
    }

    changeToHorizontalMenu() {
        this.layoutMode = MenuOrientation.HORIZONTAL;
    }

    changeToSlimMenu() {
        this.layoutMode = MenuOrientation.SLIM;
    }

    onMenuStateChange(key: string) {
        this.menuSource.next(key);
    }

    reset() {
        this.resetSource.next();
    }

}
