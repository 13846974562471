
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorProvider } from '../../error/providers/error.provider';
import { Authentication } from './authentication';
import { User } from './user';

/**
 * Provedor de error para criaÃ§Ã£o de erros de notificaÃ§Ã£o.
 * @class
 */
@Injectable({
    providedIn: 'root',
})
export class NotAuthenticatedErrorProvider implements ErrorProvider {

    constructor(private authenticationService: Authentication<User>) { }

    /**
     * MÃ©todo para identificar se o usuÃ¡rio estÃ¡ logado
     * @public
     * @param {HttpErrorResponse} error
     * @returns void
     */
    shouldHandle(error: Error | HttpErrorResponse): Boolean {
        return error instanceof HttpErrorResponse && error.status == 401;
    }

    /**
     * MÃ©todo para redirecionar caso o usuÃ¡rio nÃ£o esteja
     * @public
     * @param {HttpErrorResponse} error
     * @returns void
     */
    handle(error: HttpErrorResponse): void {
        this.authenticationService.redirect();
    }

}
