import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "check"
})
export class CheckPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        return value ? "Sim" : "NÃ£o";
    }

}
