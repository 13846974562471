import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BlockUiInterceptor, BlockUiService } from '@nuvem/angular-base';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BlockUiComponent } from './block-ui.component';

/**
 * Class BlockUiModule
 * @class
 */
@NgModule({
    imports: [
        CommonModule,
        BlockUIModule,
        ProgressSpinnerModule,
    ],
    declarations: [
        BlockUiComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BlockUiInterceptor,
            multi: true
        },
        BlockUiService
    ],
    exports: [
        BlockUIModule,
        BlockUiComponent,
        ProgressSpinnerModule
    ]
})
export class BlockUiModule {
}
