import { ApplicationProblemType } from '../types/application-problem.type';

/**
 * Abstract provider NotificationProvider
 * @abstract
 * @class
 */
export abstract class NotificationProvider {

    /**
     * Metodo para adicionar menssagem de erro
     * @param {string} message
     * @param {string} title
     * @returns void
     */
    abstract addErrorMessage(message: string, title?: string): void;

    /**
     * Metodo para adiÃ§Ã£o de erros
     * @param {ApplicationProblemType} problem
     * @returns void
     */
    abstract addErrorProblem(problem: ApplicationProblemType): void;

}
