import { Component, Input } from '@angular/core';

/**
 * Class PageNotificationComponent
 * @class
 */
@Component({
  selector: 'basis-page-notification',
  templateUrl: './page-notification.component.html'
})
export class PageNotificationComponent {

    /**
     * duration property
     * @type {number}
     */
    @Input() duration: number = 3000;

}
