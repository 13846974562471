
import { Injectable, Inject } from '@angular/core';
import { AuthConfig, AUTH_CONFIG } from '../config/auth-config';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { User } from './user';
import { Authentication } from './authentication';
import { HttpClient } from '@angular/common/http';
import { tap, filter } from 'rxjs/operators';

export function isAuthenticated(config: AuthConfig): boolean {
    return null !== config.storage.getItem(config.userStorageIndex);
};

export function redirect(config: AuthConfig): void {
    window.location.href = config.loginUrl;;
};

/**
 * ServiÃ§o de autenticaÃ§Ã£o
 * @class
 */
@Injectable({
    providedIn: 'root',
})
export class AuthenticationService<U extends User> extends Authentication<U> {

    loginNotifications: BehaviorSubject<User> = new BehaviorSubject<User>(this.getUser());

    constructor(@Inject(AUTH_CONFIG) private config: AuthConfig, private http: HttpClient) {
        super();
    }

    /**
     * MÃ©todo para verificar se o usuÃ¡rio esta autenticado na aplicaÃ§Ã£o
     * @returns boolean
     * @public
     */
    isAuthenticated(): boolean {
        return isAuthenticated(this.config);
    }

    /**
     * MÃ©todo responsÃ¡vel por realizar redirecionamentos
     * @returns void
     * @public
     */
    redirect() {
        redirect(this.config);
    }

    /**
     * MÃ©todo responsÃ¡vel pelo login da aplicaÃ§Ã£o
     * @public
     * @param {User} user
     * @returns Observable<any>
     */
    login() {
        this.getUserDetails()
            .subscribe((user) => {
                this.setUser(user);
                this.loginNotifications.next(user)
            });
    }

    /**
     * MÃ©todo responsÃ¡vel pelo logou da aplicaÃ§Ã£o
     * @public
     * @returns void
     */
    logout() {
        this.loginNotifications.next(null);
        this.config.storage.removeItem(this.config.userStorageIndex);
        window.location.href = this.config.logoutUrl;
    }

    /**
     * MÃ©todo responsÃ¡vel por obter os dados do usuÃ¡rio logado
     * @public
     * @returns Observable<any>
     */
    public getUserDetails(): Observable<U> {
        return this.http.get<U>(this.config.baseUrl + this.config.detailsUrl);
    }

    private setUser(user: U) {
        this.config.storage.setItem(this.config.userStorageIndex, JSON.stringify(user));
    }

    getUser(): U {
        return JSON.parse(this.config.storage.getItem(this.config.userStorageIndex));
    }

    getLoginNotifications(): Observable<User> {
        return this.loginNotifications.pipe(filter((value) => value != null));
    }

}
