import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'basis-action-button',
    templateUrl: './action-button.component.html',
    styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {
    
    action = false;

    constructor() {}

    ngOnInit(): void {}
}
