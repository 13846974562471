import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";

import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

import { FormNotificationService } from "../services/form-notification.service";
import { CleanFormNotification } from "../types/clean-form-notification.type";

/**
 * ImplementaÃ§Ã£o de classe para interceptar e tratar requisiÃ§Ãµes http/https
 * @class
 */
@Injectable({
    providedIn: 'root',
})
export class FormNotificationInterceptor implements HttpInterceptor {

    /**
     * MÃ©todo construtor
     * @constructor
     * @param {AbstractAuthentication} auth
     */
    constructor(private formNotificationService: FormNotificationService) {}

    /**
     * MÃ©todo responsÃ¡vel por interceptar requisiÃ§Ãµes HTTP/HTTPS
     * @public
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns Observable<HttpEvent<any>>
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.formNotificationService
            .notify(new CleanFormNotification(request.url));
        return next.handle(request);
    }

}
