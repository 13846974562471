import { User } from "./user";
import { Observable } from "rxjs";

/**
 * Classe abstrata para serviÃ§o de autenticaÃ§Ã£o
 * @class
 */
export abstract class Authentication<U extends User> {

    /**
     * Metodo responsÃ¡vel por checar se o usuÃ¡rio esta autenticado na aplicaÃ§Ã£o
     * @returns boolean
     * @abstract
     * @public
     */
    abstract isAuthenticated(): boolean;

    /**
     * Metodo responsÃ¡vel pelo login da aplicaÃ§Ã£o
     * @public
     */
    abstract login();

    /**
     * Metodo responsÃ¡vel pelo logou da aplicaÃ§Ã£o
     * @public
     * @returns void
     */
    abstract logout();

    /**
     * Metodo responsÃ¡vel por obter os dados do usuÃ¡rio logado
     * @public
     * @return User
     */
    abstract getUser(): U;

    abstract redirect();

    abstract getLoginNotifications(): Observable<User>;

}
