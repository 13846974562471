import {
    ChangeDetectorRef,
    Component, 
    Input, 
    OnDestroy, OnInit, 
    Output, 
    EventEmitter
} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MenuItem } from 'primeng/api';
import { MenusService } from './menu.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: '[submenu]',
    templateUrl: './submenu.component.html',
    host: {
        '[class.layout-root-menuitem]': 'root || active',
        '[class.active-menuitem]': '(active)'
    },
    animations: [
        trigger('children', [
            state('void', style({
                height: '0px',
                padding: '0px'
            })),
            state('hiddenAnimated', style({
                height: '0px',
                padding: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*'
            })),
            state('hidden', style({
                height: '0px',
                padding: '0px'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('void => visibleAnimated, visibleAnimated => void',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class SubMenuComponent implements OnInit, OnDestroy {

    @Input() item: any;

    @Input() root: boolean;

    @Input() visible: boolean;

    @Input() parentKey: string;

    @Input() index: number;

    active = false;

    _reset: boolean;

    _parentActive: boolean;

    activeIndex: number;

    key: string;

    menuSourceSubscription: Subscription;

    menuResetSubscription: Subscription;

    @Output() stateChanged = new EventEmitter<boolean>(true);

    constructor(public menuService: MenusService, public router: Router, private changeDetectorRef: ChangeDetectorRef) {

        this.menuSourceSubscription = this.menuService.menuSource$.subscribe(key => {
            // deactivate current active menu
            if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
                this.active = false;
            }
        });

        this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
            this.active = false;
        });

        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(params => {
                if (this.menuService.isHorizontal() || this.menuService.isSlim()) {
                    this.active = false;
                } else {
                    if (this.item.routerLink) {
                        this.updateActiveStateFromRoute();
                    }
                }
            });

    }

    ngOnInit() {
        if (!(this.menuService.isHorizontal() || this.menuService.isSlim()) && this.item.routerLink) {
            this.updateActiveStateFromRoute();
        }

        this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);
    }

    updateActiveStateFromRoute() {
        this.active = this.router.isActive(this.item.routerLink[0], this.item.items ? false : true);
        
        if (this.active) {
            this.stateChanged.emit(this.active);
        }
        
    }

    onChildStateChange(state: boolean) {
        if (state) {
           this.active = true;
           this.stateChanged.emit(this.active);
        }
    }

    /**
     * Verifica se foi informado um comando para o item de menu e executa caso necessÃ¡rio
     * @param item menu
     */
    private execCommand(event: Event, item: MenuItem) {
        if (item.command) {
            item.command({ 'originalEvent': event, 'item': item });
            return true;
        }
        return false;
    }

    itemClick(event: Event) {
        // avoid processing disabled items
        if (this.item.disabled) {
            event.preventDefault();
            return true;
        }

        // navigate with hover in horizontal mode
        if (this.root) {
            this.menuService.menuHoverActive = !this.menuService.menuHoverActive;
        }

        // notify other items
        this.menuService.onMenuStateChange(this.key);

        // execute command
        if (this.item.command) {
            this.item.command({ originalEvent: event, item: this.item });
        }

        // toggle active state
        if (this.item.items) {
            this.active = !this.active;
        } else {
            // activate item
            this.active = true;

            // reset horizontal and slim menu
            if (this.menuService.isHorizontal() || this.menuService.isSlim()) {
                this.menuService.reset();
                this.menuService.menuHoverActive = false;
            }

            if (!this.menuService.isStatic()) {
                this.active = false;
            }
        }

        this.removeActiveInk(event);
    }

    onMouseEnter() {
        // activate item on hover
        if (this.root) {
            this.active = true;
        }
    }

    isActive(index: number): boolean {
        if (this.root) {
            return true;
        }

        return this.activeIndex === index;
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;

        if (this._reset && (this.menuService.isHorizontal() || this.menuService.isSlim())) {
            this.activeIndex = null;
        }
    }

    @Input() get parentActive(): boolean {
        return this._parentActive;
    }

    set parentActive(val: boolean) {
        this._parentActive = val;

        if (!this._parentActive) {
            this.activeIndex = null;
        }
    }

    removeActiveInk(event: Event) {
        let currentTarget = (event.currentTarget as HTMLElement);
        setTimeout(() => {
            if (currentTarget) {
                let activeInk = currentTarget.querySelector('.p-ink-active');
                if (activeInk) {
                    if (activeInk.classList)
                        activeInk.classList.remove('p-ink-active');
                    else
                        activeInk.className = activeInk.className.replace(new RegExp('(^|\\b)' + 'p-ink-active'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
                }
            }
        }, 401);
    }

    ngOnDestroy() {
        if (this.menuSourceSubscription) {
            this.menuSourceSubscription.unsubscribe();
        }

        if (this.menuResetSubscription) {
            this.menuResetSubscription.unsubscribe();
        }
    }
}
