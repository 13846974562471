import { Component } from '@angular/core';
import { MenusService } from './menu.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
})
export class MenuComponent {

    constructor(public menuService: MenusService) {}

}
