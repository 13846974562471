/**
 * Anstract class ErrorProvider
 * @class
 * @abstract
 */
export abstract class ErrorProvider {
    /**
     * Metodo responsÃ¡vel pela identificaÃ§Ã£o de erros
     * @param {Error} error
     * @returns Boolean
     */
    abstract shouldHandle(error: Error): Boolean;

    /**
     * Metodo pela adiÃ§Ã£o de erros
     * @param {Error} error
     * @returns void
     */
    abstract handle(error: Error): void;
}
