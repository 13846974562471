import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormNotificationService } from './form-notification.service';
import { ErrorProvider } from '../../error/providers/error.provider';
import { FieldFormNotification } from '../types/field-form-notification.type';

/**
 * Provedor de error para criaÃ§Ã£o de erros de notificaÃ§Ã£o.
 * @class
 */
@Injectable({
    providedIn: 'root',
})
export class FormNotificationErrorProvider implements ErrorProvider {

    /**
     * MÃ©todo construtor
     * @param {FormtNotificationService} formNotificationService
     * @constructor
     */
    constructor(private formNotificationService: FormNotificationService) { }

    /**
     * MÃ©todo responsÃ¡vel pela identificaÃ§Ã£o de erros
     * @public
     * @param {Error | HttpErrorResponse} error
     * @returns Boolean
     */
    shouldHandle(error: Error | HttpErrorResponse): Boolean {
        return error instanceof HttpErrorResponse &&
            error.headers.get('Content-Type') === 'application/problem+json' &&
            error.error && (error.error.violations || error.error.fieldErrors);
    }

    /**
     * MÃ©todo para emissÃ£o de erros
     * @public
     * @param {HttpErrorResponse} error
     * @returns void
     */
    handle(error: HttpErrorResponse): void {
        if (error.error.violations) {
            this.violation(error.url, error.error.violations);
        }
        if (error.error.fieldErrors) {
            this.violation(error.url, error.error.fieldErrors);
        }
    }

    /**
     * MÃ©todo iteraÃ§Ã£o de violaÃ§Ãµes e emissÃ£o de erros
     * @param values
     */
    violation(form: string, values: ({ field: string, message: string })[]) {
        values.forEach(violation =>
            this.formNotificationService.notify(
                new FieldFormNotification(
                    form, violation.field, violation.message)));
    }

}
