import { InjectionToken } from '@angular/core';

/**
 * Interface para dados de configuraÃ§Ã£o do Version Tag
 * @interface
 */
export interface VersionTagConfig {

    /**
     * Propriedade que determina a versÃ£o da aplicaÃ§Ã£o
     * @type {sring}
     */
    appVersion: string;
}

/**
 * Constante de configuraÃ§Ã£o
 * @const
 */
export const VERSIONTAG_CONFIG = new InjectionToken<VersionTagConfig>('versiontag.config');
