import { Component, OnInit, Input, Inject } from '@angular/core';
import { VERSIONTAG_CONFIG, VersionTagConfig } from './version-tag.config';

/**
 * Componente responsÃ¡vel por expor o seletor <app-version-tag></app-version-tag> para reuso
 * @class
 */
@Component({
    selector: 'app-version-tag',
    templateUrl: './version-tag.component.html'
})
export class VersionTagComponent implements OnInit {


    constructor(@Inject(VERSIONTAG_CONFIG) private config: VersionTagConfig) { }

    /**
     * Propriedade de para definiÃ§Ã£o para classe CSS
     * @public
     * @type {string}
     */
    @Input() cssClass: string;

    /**
     * Propriedade para definiÃ§Ã£o de versÃ£o
     * @public
     * @type {string}
     */
    version: string;

    /**
     * Metodo executado ao carregar o componente responsÃ¡vel por carregar o arquivo 'package.json'
     * @return void
     */
    ngOnInit() {
        this.version = this.config.appVersion;
    }
}
