import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService, Message, MenuItem } from 'primeng/api';
import { BreadcrumbService } from './breadcrumb.service';
import { AccessbilityService } from '@nuvem/angular-base';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, AfterViewInit {

    subscription: Subscription;

    items: MenuItem[];

    highContrastEnabled = false;

    home: MenuItem;

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly breadcrumbService: BreadcrumbService,
        private readonly messages: MessageService,
        private readonly accessibilityService: AccessbilityService
    ) {
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });

        this.home = { icon: 'pi pi-home', routerLink: '/' };
    }

    ngOnInit() {
        this.router.events.pipe(filter(
            (event: any) => event instanceof NavigationEnd),
            distinctUntilChanged(),
        ).subscribe((event) => {
            this.items = this.buildBreadCrumb(this.activatedRoute.root);
        })
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    enableHighContrast() {
        this.highContrastEnabled = true;
        this.accessibilityService.enableHighContrast();
    }

    disableHighContrast() {
        this.highContrastEnabled = false;
        this.accessibilityService.disableHighContrast();
    }

    increaseFontSize() {
        this.accessibilityService.increaseFontSize();
    }

    decreaseFontSize() {
        this.accessibilityService.decreaseFontSize();
    }

    ngAfterViewInit() {
        this.messages.messageObserver.subscribe(
            (msg: Message) => {
                this.accessibilityService.addAccessibilityMessages({ severity: msg.severity });
            }
        );
        this.accessibilityService.addAccessibilityIcons();
    }

    buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItem[] = []): MenuItem[] {

        let label = (route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '').trim();
        let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

        const lastRoutePart = path.split('/').pop();
        const isDynamicRoute = lastRoutePart.startsWith(':');

        if (isDynamicRoute && !!route.snapshot) {
            for (let key in route.snapshot.params) {
                path = path.replace(`:${key}`, route.snapshot.params[key]);
            }

            label = (`${(label != ':action')? label : ''} ${Object.values(route.snapshot.params).join(' ')}`).trim();
        }

        const nextUrl = path ? `${url}/${path}` : url;

        const menuItem: MenuItem = {
            "label": label.charAt(0).toUpperCase() + label.slice(1),
            "routerLink": nextUrl
        }

        const newBreadcrumbs = menuItem.label ? [...breadcrumbs, menuItem] : [...breadcrumbs];

        if (route.firstChild) {
            return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
        }

        return newBreadcrumbs;
    }

}
