import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Authentication } from './authentication';
import { User } from './user';

/**
 * Guard que verifica se usuÃ¡rio estÃ¡ autenticado antes do usuÃ¡rio acessar uma rota. 
 * Se for verificar que o usuÃ¡rio nÃ£o estÃ¡ autenticado, Ã© feito um redirecionamento para o login.
 * @class
 */
@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {

    /**
     * MÃ©todo construtor responsÃ¡vel pela injeÃ§Ã£o do serviÃ§o de autenticaÃ§Ã£o
     * @constructor
     * @param {Authentication} auth
     */
    constructor(private authenticationService: Authentication<User>) {}

    /**
     * MÃ©todo responsÃ¡vel por identificar se o usuÃ¡rio estÃ¡ autenticado
     * @public
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns boolean
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authenticationService.isAuthenticated()) {
            return true;
        }
        this.authenticationService.redirect();
        return false;
    }

    /**
     * MÃ©todo responsÃ¡vel por atuar somente em rotas filhas de modo independente
     * @public
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns boolean
     */
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

}
