import { BlockUiStatus, BlockUiService } from '@nuvem/angular-base';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * Class BlockUiComponent
 * @class
 */
@Component({
    selector: 'basis-block-ui',
    templateUrl: './block-ui.component.html',
    styleUrls: [ './block-ui.component.css' ],
})
export class BlockUiComponent implements OnInit , OnDestroy {
    /**
     * status property
     * @type {boolean}
     * @public
     */
    status = false;

    /**
     * subscription property
     * @private
     * @type {Subscription}
     */
    private subscription: Subscription;

    /**
     * constructor method
     * @constructor
     * @param {BlockUiService} service
     */
    constructor(private service: BlockUiService) { }

    /**
     * ngOnInit method
     * @returns void
     */
    ngOnInit() {
        this.subscription = this.service.loaderStatus.subscribe((blockui: BlockUiStatus) => {
            this.status = blockui.status;
        });
    }

    /**
     * ngOnDestroy methods
     * @returns void
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /**
     * isBlocked method
     * @returns boolean
     */
    isBlocked() {
        return this.status;
    }

    /**
     * show method
     * @returns false
     */
    show() {
        this.status = true;
    }

    /**
     * hide methods
     * @returns void
     */
    hide() {
        this.status = false;
    }
}
