import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
/**
 * Class DatatableButtonComponent
 * @class
 */
@Component({
    selector: 'basis-datatable-button',
    templateUrl: './datatable-button.component.html'
})
export class DatatableButtonComponent {

    /**
     * icon property
     * @type {string}
     */
    @Input()
    icon: string;

    /**
     * class property
     * @type {string}
     */
    @Input()
    class: string;

    /**
     * bTooltip property
     * @type {string}
     */
    @Input()
    bTooltip: string;

    /**
     * name property
     * @type {string}
     */
    @Input()
    name: string;

    /**
     * allowMultipleSelection property
     * @type {boolean}
     */
    @Input()
    allowMultipleSelection: boolean = true;

    /**
     * disabled property
     * @type boolean
     */
    @Input()
    disabled: boolean = false;

    /**
     * click property
     * @type {EventEmitter<any>}
     */
    @Output()
    click: EventEmitter<any> = new EventEmitter<any>();

    /**
     * onClick method
     * @param {any} event
     * @return void
     */
    onClick(event: any) {
        this.click.emit(event);
        event.stopPropagation();
    }
}
