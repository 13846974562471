import { VersionTagComponent } from './version-tag.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { VersionTagConfig, VERSIONTAG_CONFIG } from './version-tag.config';

/**
 * O componente VersionTag exibe o atributo `version` do arquivo `package.json` do sistema,
 * com o intuito de informar o usuÃ¡rio a versÃ£o atual da aplicaÃ§Ã£o. Inicialmente foi feito para
 * ser utilizado no rodapÃ© das aplicaÃ§Ãµes conforme o exemplo a seguir.
 * @module
 */
@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    declarations: [
        VersionTagComponent
    ],
    exports: [
        VersionTagComponent
    ]
})
export class VersionTagModule {

    /**
     * forRoot method
     * @public
     * @static
     * @returns ModuleWithProviders
     */
    static forRoot(config: VersionTagConfig): ModuleWithProviders<VersionTagModule> {
        return {
            ngModule: VersionTagModule,
            providers: [
                { provide: VERSIONTAG_CONFIG, useValue: config },
            ]
        };
    }
}
