import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { Authorization } from './authorization';
import { Authentication } from '../authentication';
import { User } from '../user';
import { Subscription } from 'rxjs';

/**
 * HasRoleDirective diretiva para checagem da existÃªncia de permissÃµes de acesso para o usuÃ¡rio logado.
 * @class
 */
@Directive( {
    selector: '[hasRole]'
})
export class HasRoleDirective implements OnDestroy {

    private loginNotifications: Subscription;

    /**
     * MÃ©todo construtor responsÃ¡vel por carregar o serviÃ§o de autorizaÃ§Ã£o e serviÃ§o de identificaÃ§Ã£o de mudanÃ§a
     * @param {TemplateRef<any>} templateRef
     * @param {ViewContainerRef} viewContainer
     * @param {Authorization} authorization
     * @param {Authentication<User>} authentication
     */
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authorization: Authorization,
        private authentication: Authentication<User>) { }

    /**
     * Propriedade para identificar regras de acesso aos itens
     * @type {string}
     */
    @Input() set hasRole(hasRole: string | string[]) {
        this.viewContainer.clear();
        if (hasRole) {
            if (this.loginNotifications) this.loginNotifications.unsubscribe();
            this.loginNotifications = this.authentication.getLoginNotifications().subscribe(() => {
                if (this.authorization.hasRole(hasRole)) {
                    this.viewContainer.clear();
                    this.viewContainer.createEmbeddedView(this.templateRef);
                }
            });
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

    ngOnDestroy(): void {
        if (this.loginNotifications) {
            this.loginNotifications.unsubscribe();
        }
    }

}
