import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormNotificationBaseModule, FormNotificationComponentSuperclass } from '@nuvem/angular-base';
import { FormNotificationComponent } from './form-notification.component';

/**
 * MÃ³dulo para adicionar funcionalidade de notificaÃ§Ã£o de erros de formulÃ¡rios.
 * @module
 */
@NgModule({
    imports: [
        CommonModule,
        FormNotificationBaseModule
    ],
    declarations: [
        FormNotificationComponent
    ],
    exports: [
        FormNotificationComponent
    ]
})
export class FormNotificationModule { }
