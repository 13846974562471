import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

import { ErrorStackComponent } from './error-stack.component';
import {
    ErrorProvider,
    ErrorStackService,
    ErrorStackProviderService,
    ClipboardModule
} from '@nuvem/angular-base';

/**
 * Class ErrorStackModule
 * @class
 */
@NgModule({
    imports: [
        CommonModule,
        TableModule,
        ButtonModule,
        TooltipModule,
        ClipboardModule
    ],
    declarations: [
        ErrorStackComponent
    ],
    providers: [
        ErrorStackService,
        {
            provide: ErrorProvider,
            useClass: ErrorStackProviderService,
            multi: true
        }
    ],
    exports: [
        ErrorStackComponent
    ]
})
export class ErrorStackModule {
}
