import { Component, Input, OnInit } from "@angular/core";
import { provideValueAccessor } from "@nuvem/angular-base";
import { FieldComponent } from "../field.component";

@Component({
    selector: "[nInputText]",
    templateUrl: "./input-text.component.html",
    providers: [ provideValueAccessor(InputTextComponent) ],
})
export class InputTextComponent extends FieldComponent<string> implements OnInit {

    @Input() minLength: number;

    @Input() maxLength: number;

    @Input() min: number;

    @Input() max: number;

    @Input() pattern: string | RegExp;

    @Input() type: string = "text";

    ngOnInit() {
        super.ngOnInit();
        this.setElementRefAttr("min", this.min);
        this.setElementRefAttr("max", this.max);
        this.setElementRefAttr("minLength", this.minLength);
        this.setElementRefAttr("maxLength", this.maxLength);
        this.setElementRefAttr("pattern", this.pattern);
    }

}
