import {
    Component, OnInit, Inject, Input, Output, EventEmitter, AfterContentInit,
    ViewChild, ContentChildren, QueryList, ChangeDetectorRef
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { CrudService, CRUD_SERVICE } from "./crud-service.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Column } from '../datatable/p-column.directive';
import { DatatableComponent } from '../datatable/datatable.component'
import { DatatableClickEvent } from '../datatable/datatable-click-event'
import { ConfirmationService } from 'primeng/api';
import { DatatableParams } from "../datatable/datatable-params";

@Component({
    selector: 'nCrudList',
    templateUrl: './crud-list.component.html'
})
export class CrudListComponent implements OnInit, AfterContentInit {

    @Input() form: FormGroup;

    @Input() action: string = "findAll";

    @Input() findLabel: string = "Pesquisar";

    @Input() service: CrudService<any, any>;

    @Input() url: string;

    @Input() disableSave = false;

    @Input() disableEdit = false;

    @Input() disableView = false;

    @Input() disableDelete = false;

    @Input() datatableParams: DatatableParams = {};

    @Input() datatableExtraParams: any;

    @Output() find: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('datatable', { static: true }) datatable: DatatableComponent;

    @ContentChildren(Column) columns: QueryList<Column>;

    result: any[];

    constructor(
        @Inject(CRUD_SERVICE)
        public crudService: CrudService<any, any>,
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        private router: Router
    ) {  }

    ngOnInit(): void {
        if (this.service) {
            this.crudService = this.service;
        }
        if (!this.url) {
            this.url = this.crudService.uri
        }
    }

    datatableClick(event: DatatableClickEvent) {
        if (event.selection) {
            switch (event.button) {
                case "edit":
                case "view":
                    this.router.navigate([`../${event.button}`, event.selection.id], { relativeTo: this.route });
                    break;
                case "delete":
                    this.confirmationService.confirm({
                        message: 'VocÃª tem certeza que deseja excluir o registro?',
                        accept: () => this.crudService.delete(event.selection.id)
                            .subscribe(() => this.datatable.filter())
                    });
                    break;
            }
        }
    }

    findAll(event: any) {
        if (this.find.observers.length) {
            this.find.emit(this.form.value);
        } else {
            this.datatable.filterParams = {};
            Object.keys(this.form.value).forEach(element => {
                let value = this.form.value[element];
                if (value) {
                    this.datatable.filterParams[element] =
                        value instanceof Date ? JSON.stringify(value).replace(/"/g, "") : value;
                }
            });
            this.datatable.filter();
        }
    }

    ngAfterContentInit() {
        const datatableNgAfterContentInit = this.datatable.pDatatableComponent.ngAfterContentInit;
        this.datatable.pDatatableComponent.ngAfterContentInit = () => {
            this.datatable.pDatatableComponent.columns = this.columns.toArray();
            datatableNgAfterContentInit.call(this.datatable.pDatatableComponent);
        };
    }

}
