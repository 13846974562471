import { FormNotification, FormNotificationType } from "./form-notification.type";

/**
 * Class modelo para limpeza de validaÃ§Ã£o de formulÃ¡rios
 * @class
 */
export class CleanFormNotification extends FormNotification {

    /**
     * MÃ©todo construtor para limpeza de validaÃ§Ã£o de formulÃ¡rios
     * @param {string} form
     */
    constructor(form: string) {
        super(form, FormNotificationType.CLEAN);
    }

}
