import { FormNotificationService } from '../services/form-notification.service';
import { Directive, OnInit, OnDestroy, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { FormNotification } from '../types/form-notification.type';
import { FieldFormNotification } from '../types/field-form-notification.type';

@Directive({
    selector: 'form-notification-component-superclass'
})
export class FormNotificationComponentSuperclass implements OnInit, OnDestroy {

    private subscription: Subscription;

    @Input() name: String;

    @Input() form: String;

    message: String;

    /**
     * MÃ©todo construtor responsÃ¡vel por carregar o serviÃ§o de validaÃ§Ã£o
     * @param formNotificationService
     */
    constructor(protected formNotificationService: FormNotificationService) {}

    /**
     * MÃ©todo Inicial para subscriÃ§Ã£o no endpoint responsÃ¡vel por validar o formulÃ¡rio
     */
    ngOnInit(): void {
        this.subscription = this.formNotificationService
            .notifications(this.form, this.name)
            .subscribe((notification: FormNotification) => {
                if (notification instanceof FieldFormNotification) {
                    this.message = notification.message;
                } else {
                    this.message = null;
                }
            });
    }

    /**
     * MÃ©todo responsÃ¡vel por realizar a saida das subscriÃ§Ãµes do endpont de validaÃ§Ã£o
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
