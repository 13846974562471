import { InjectionToken } from '@angular/core';

/**
 * Interface para dados de configuraÃ§Ã£o AuthConfig
 * @interface
 */
export interface AuthConfig {

    /**
     * Propriedade para URL base
     * @type {sring}
     */
    baseUrl: string;

    /**
     * Propriedade para URL de login
     * @type {sring}
     */
    loginUrl: string;

    /**
     * Rota de navegaÃ§Ã£o quando o usuÃ¡rio for logado com sucesso na aplicaÃ§Ã£o.
     * @type {sring}
     */
    loginSuccessRoute: string;

    /**
     * Propriedade para URL de logOut
     * @type {string}
     */
    logoutUrl: string;

    /**
     * Propriedade para URL de dados do usuÃ¡rio
     * @type {string}
     */
    detailsUrl: string;

    /**
     * Propriedade para definiÃ£o de storage
     * @type {Storage}
     */
    storage: Storage;

    /**
     * Propriedade para identificar o idice do usuÃ¡rio no storage
     * @type {string}
     */
    userStorageIndex: string;

    /**
     * Propriedade para identificar o idice do token no storage
     * @type {string}
     */
    tokenStorageIndex?: string;

}

/**
 * Constante de configuraÃ§Ã£o
 * @const
 */
export const AUTH_CONFIG = new InjectionToken<AuthConfig>('auth.config');
