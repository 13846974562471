import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './breadcrumb.component';
import { BreadcrumbService } from './breadcrumb.service';
import { RouterModule } from '@angular/router';
import { BreadcrumbModule as PrimengBreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';


@NgModule({
    declarations: [BreadcrumbComponent],
    imports: [
        CommonModule,
        RouterModule,
        PrimengBreadcrumbModule,
        ButtonModule
    ],
    exports: [
        BreadcrumbComponent
    ],
    providers: [
        BreadcrumbService
    ]
})
export class BreadcrumbModule { }
