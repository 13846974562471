import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

import { PageNotificationService } from './page-notification.service';
import { PageNotificationComponent } from './page-notification.component';
import { NotificationProvider } from '@nuvem/angular-base';

/**
 * Class PageNotificationModule
 * @class
 */
@NgModule({
    imports: [
        CommonModule,
        ToastModule
    ],
    declarations: [
        PageNotificationComponent
    ],
    exports: [
        PageNotificationComponent
    ],
    providers: [
        MessageService,
        PageNotificationService,
        {
            provide: NotificationProvider,
            useExisting: PageNotificationService
        }
    ]
})
export class PageNotificationModule {
}
